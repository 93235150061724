var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasLoaded
    ? _c(
        "travio-center-container",
        {
          attrs: {
            "grid-width": "1/2",
            pageTitle: "Edit - " + _vm.supplierName,
          },
        },
        [
          _c(
            "vx-card",
            [
              _c(
                "div",
                { staticClass: "flex flex-wrap items-center mb-4" },
                [
                  _c("vs-avatar", {
                    staticClass: "supplier-logo-avatar mr-2",
                    attrs: {
                      src: _vm.supplierLogo,
                      text: _vm.supplierName,
                      size: "60px",
                    },
                  }),
                  _c("div", { staticClass: "ml-2" }, [
                    _c("h2", { staticClass: "supplier-name-heading" }, [
                      _vm._v(
                        _vm._s(_vm.supplierName) +
                          " (ID: " +
                          _vm._s(_vm.threadId) +
                          ")"
                      ),
                    ]),
                    _c("p", { staticClass: "text-muted text-sm mt-1" }, [
                      _vm._v(
                        _vm._s(_vm.productType) + "  •  " + _vm._s(_vm.apiType)
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "vx-row mb-6" }, [
                _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                  _c("span", [_vm._v("Description*")]),
                ]),
                _c(
                  "div",
                  { staticClass: "vx-col sm:w-4/5 w-full" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|max:50",
                          expression: "'required|max:50'",
                        },
                      ],
                      staticClass: "w-full",
                      attrs: { name: "Description" },
                      model: {
                        value: _vm.form.description,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "description", $$v)
                        },
                        expression: "form.description",
                      },
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("Description"))),
                    ]),
                  ],
                  1
                ),
              ]),
              _vm.form.usernameLabel
                ? _c("div", { staticClass: "vx-row mb-6" }, [
                    _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                      _c("span", [_vm._v(_vm._s(_vm.form.usernameLabel))]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "vx-col sm:w-4/5 w-full" },
                      [
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:1250",
                              expression: "'max:1250'",
                            },
                          ],
                          staticClass: "w-full",
                          attrs: { name: "Username" },
                          model: {
                            value: _vm.form.username,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "username", $$v)
                            },
                            expression: "form.username",
                          },
                        }),
                        _c("span", { staticClass: "text-danger text-sm" }, [
                          _vm._v(_vm._s(_vm.errors.first("Username"))),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.form.passwordLabel
                ? _c("div", { staticClass: "vx-row mb-6" }, [
                    _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                      _c("span", [_vm._v(_vm._s(_vm.form.passwordLabel))]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "vx-col sm:w-4/5 w-full" },
                      [
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:1250",
                              expression: "'max:1250'",
                            },
                          ],
                          staticClass: "w-full",
                          attrs: { name: "Password" },
                          model: {
                            value: _vm.form.password,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "password", $$v)
                            },
                            expression: "form.password",
                          },
                        }),
                        _c("span", { staticClass: "text-danger text-sm" }, [
                          _vm._v(_vm._s(_vm.errors.first("Password"))),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.hasEndpoint
                ? _c("div", { staticClass: "vx-row mb-6" }, [
                    _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                      _c("span", [_vm._v("Endpoint")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "vx-col sm:w-4/5 w-full" },
                      [
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|max:500",
                              expression: "'required|max:500'",
                            },
                          ],
                          staticClass: "w-full",
                          attrs: { name: "Endpoint" },
                          model: {
                            value: _vm.form.endpoint,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "endpoint", $$v)
                            },
                            expression: "form.endpoint",
                          },
                        }),
                        _c("span", { staticClass: "text-danger text-sm" }, [
                          _vm._v(_vm._s(_vm.errors.first("Endpoint"))),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "vx-row mt-4 mb-6" }, [
                _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                  _c("span", [_vm._v("Is Enabled")]),
                ]),
                _c(
                  "div",
                  { staticClass: "vx-col sm:w-4/5 w-full" },
                  [
                    _c("vs-switch", {
                      attrs: { name: "Is Active" },
                      model: {
                        value: _vm.form.isActive,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "isActive", $$v)
                        },
                        expression: "form.isActive",
                      },
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("Is Active"))),
                    ]),
                  ],
                  1
                ),
              ]),
              _vm.isActiveAppSharingSuppliers
                ? _c("div", { staticClass: "vx-row mt-4 mb-6" }, [
                    _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                      _c("span", [_vm._v("Is Shared")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "vx-col sm:w-4/5 w-full" },
                      [
                        _c("vs-switch", {
                          attrs: { name: "Is Shared" },
                          model: {
                            value: _vm.form.isShared,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "isShared", $$v)
                            },
                            expression: "form.isShared",
                          },
                        }),
                        _c("span", { staticClass: "text-danger text-sm" }, [
                          _vm._v(_vm._s(_vm.errors.first("Is Shared"))),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.form.isCredentialInvalid
                ? _c(
                    "vs-alert",
                    {
                      staticClass: "h-full my-4",
                      attrs: {
                        "icon-pack": "feather",
                        icon: "icon-alert-triangle",
                        color: "warning",
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "This integration was automatically disabled on " +
                            _vm._s(_vm.formattedCredentialInvalidDate) +
                            " due to invalid credentials being detected. If you have resolved this issue, just re-save the settings to re-enable."
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c("div", { staticClass: "mt-10" }, [
                _c("h5", { staticStyle: { color: "#636363" } }, [
                  _vm._v("Additional Settings"),
                ]),
              ]),
              _c("ag-grid-vue", {
                ref: "agGridTable",
                staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
                style: { height: "500px" },
                attrs: {
                  components: _vm.cellRendererComponents,
                  columnDefs: _vm.columnDefs,
                  defaultColDef: _vm.defaultColDef,
                  rowData: _vm.settingsData,
                  gridOptions: _vm.gridOptions,
                  rowSelection: _vm.rowSelection,
                  pagination: false,
                  paginationPageSize: _vm.paginationPageSize,
                  suppressPaginationPanel: true,
                  context: _vm.context,
                  rowClassRules: _vm.rowClassRules,
                  enableBrowserTooltips: true,
                },
              }),
              _c(
                "div",
                { staticClass: "flex flex-wrap justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "mt-4 mr-4",
                      attrs: { type: "border", color: "danger" },
                      on: { click: _vm.handleCancel },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "mt-4 mr-4",
                      attrs: { color: "warning" },
                      on: { click: _vm.handleTest },
                    },
                    [_vm._v("Test")]
                  ),
                  _c(
                    "vs-button",
                    { staticClass: "mt-4", on: { click: _vm.onSubmit } },
                    [_vm._v("Save")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }